import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/bridge-logo.png'; // Replace with the correct path to your logo
import Footer from './Footer';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const JobApplication = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    motivation: '',
    file: null,
  });
  const [status, setStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, file: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('motivation', formData.motivation);
    if (formData.file) {
      formDataToSend.append('file', formData.file);
    }

    try {
      const response = await fetch('/api/send-job-application', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        setStatus(t('Job application sent successfully!'));
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          motivation: '',
          file: null,
        });
      } else {
        setStatus(t('Failed to send job application.'));
      }
    } catch (error) {
      console.error('Error sending job application:', error);
      setStatus(t('An error occurred. Please try again.'));
    }
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 3000); // Hide after 3 seconds
  };

  const handleFileInputClick = () => {
    document.getElementById('fileUpload').click();
  };

  return (
    <>
      <ApplicationContainer>
        <ApplicationHeader>
          <Logo src={logo} alt={t('Bridge Logistics Logo')} />
        </ApplicationHeader>
        <FormContainer>
          <FormTitle>{t('APPLY FOR A JOB AT Bridge Logistics')}</FormTitle>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="firstName"
              placeholder={t('First name')}
              value={formData.firstName}
              onChange={handleChange}
            />
            <Input
              type="text"
              name="lastName"
              placeholder={t('Last name *')}
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder={t('Email *')}
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder={t('Phone *')}
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <Textarea
              name="motivation"
              placeholder={t('Motivation')}
              value={formData.motivation}
              onChange={handleChange}
            />
            <FileUploadContainer>
              <FileLabel>{t('File Upload (pdf only)')}</FileLabel>
              <HiddenFileInput
                id="fileUpload"
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
              />
              <CustomFileButton type="button" onClick={handleFileInputClick}>
                {t('Choose File')}
              </CustomFileButton>
              <FileName id="fileName">
                {formData.file ? formData.file.name : t('No file chosen')}
              </FileName>
            </FileUploadContainer>
            <SubmitButton type="submit">{t('SEND')}</SubmitButton>
          </Form>
        </FormContainer>
        {showPopup && <Popup>{status}</Popup>}
      </ApplicationContainer>
      <Footer />
    </>
  );
};

export default JobApplication;

// Styled components including Popup animation
const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
`;

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeInOut} 3s forwards; /* 3 seconds animation */
  z-index: 1000;
`;

// Other styled components remain the same
const ApplicationContainer = styled.div`
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: ltr; /* Keeps layout direction LTR even when language is Arabic */

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ApplicationHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    padding-bottom: 15px;
  }
`;

const Logo = styled.img`
  height: 50px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const FormTitle = styled.h2`
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 10px;
  }
`;

const Textarea = styled.textarea`
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;

  @media (max-width: 768px) {
    font-size: 0.95rem;
    padding: 10px;
  }
`;

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7931e;
  padding: 15px;
  border-radius: 5px;
  color: white;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FileLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const CustomFileButton = styled.button`
  padding: 10px 20px;
  background-color: white;
  color: #f7931e;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    background-color: #e6e6e6;
  }
`;

const FileName = styled.span`
  font-size: 0.9rem;
  color: white;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #f7931e;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 1rem;
  }
`;
