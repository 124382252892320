// src/components/BlogSection.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import Image1 from '../assets/cargo-shipping-freight-logistics-truck-icon.jpg';
import Image2 from '../assets/man-working-warehouse.jpg';
import Image3 from '../assets/man-working-as-truck-driver-posing.jpg';

const BlogSection = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <SectionContainer>
      <BlogGrid>
        <BlogCard>
          <BlogTitle>{t('NEWS')}</BlogTitle>
          <BlogImage src={Image1} alt={t('News Image')} />
          <BlogDescription>
            {t('WATCH NOBODY GOES FURTHER')}
          </BlogDescription>
        </BlogCard>
        <BlogCard>
          <BlogTitle>{t("CEO'S BLOG")}</BlogTitle>
          <BlogImage src={Image2} alt={t('Blog Image')} />
          <BlogDescription>
            {t('ONE BELT, ONE ROAD...')} <Highlight>{t('CONNECTING CHINA TO THE WORLD')}</Highlight>
            <p>{t('A first-ever freight train filled with Dutch-made Machinery, Mineral fuels...')} <ReadMoreLink href="#">{t('Read more')} &gt;</ReadMoreLink></p>
          </BlogDescription>
        </BlogCard>
        <BlogCard>
          <BlogTitle>{t('WANT TO BE A')} <Highlight>{t('HAULIER?')}</Highlight></BlogTitle>
          <BlogImage src={Image3} alt={t('Haulier Image')} />
          <BlogDescription>
            <ApplyLink to="/apply">{t('APPLY NOW')}</ApplyLink> {/* Link to JobApplication component */}
          </BlogDescription>
        </BlogCard>
      </BlogGrid>
    </SectionContainer>
  );
};

export default BlogSection;

const SectionContainer = styled.section`
  padding: 50px 100px;
  background-color: #f5f5f5;

  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for mobile */
  }
`;

const BlogGrid = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack cards vertically on mobile */
    gap: 20px; /* Add spacing between cards */
  }
`;

const BlogCard = styled.div`
  flex: 1;
  margin: 0 15px;
  text-align: left;

  @media (max-width: 768px) {
    margin: 0; /* Remove side margins on mobile */
  }
`;

const BlogTitle = styled.h3`
  color: #f7931e; /* Orange color */
  font-size: 1.25rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Adjust font size for mobile */
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const BlogDescription = styled.div`
  color: #666;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
`;

const Highlight = styled.span`
  color: #f7931e;
  font-weight: bold;
`;

const ReadMoreLink = styled.a`
  color: #f7931e;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ApplyLink = styled(Link)`
  color: #f7931e;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
