// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import Header from './components/Header';
import Hero from './components/Hero';
import FeatureSection from './components/FeatureSection';
import BlogSection from './components/BlogSection';
import ValueInnovation from './components/ValueInnovation';
import AboutSection from './components/AboutSection'; // Assuming this file exists
import Industry from './components/Industry';
import Footer from './components/Footer'
import Services from './components/Services';
import Quotation from './components/Quotation';
import Team from './components/Team';
import Location from './components/Location'; // Import the Location component
import Contact from './components/Contact'; // Import Contact component
import JobApplication from './components/JobApplication';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <FeatureSection />
              <ValueInnovation />
              <BlogSection />
              <Footer/>

            </>
          }
        />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/Industry" element={<Industry />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Quotation" element={<Quotation />} />
        <Route path="/team" element={<Team />} /> {/* Add this route */}
        <Route path="/location" element={<Location />} /> {/* Add this route */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/apply" element={<JobApplication />} />
      </Routes>
    </Router>
  );
}

export default App;
