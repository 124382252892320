import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Hero1 from '../assets/trailer-trucks-driving-road-surrounded-by-beautiful-green-trees.jpg';
import LogoBridge from '../assets/bridge-logo.png';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Hero = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [backgroundImage, setBackgroundImage] = useState(''); // Placeholder for lazy loading

  useEffect(() => {
    const loadImage = () => {
      setBackgroundImage(Hero1); // Load the actual background image
    };

    // Use setTimeout to simulate lazy loading
    const timeoutId = setTimeout(loadImage, 100);

    return () => clearTimeout(timeoutId); // Cleanup timeout if the component unmounts
  }, []);

  return (
    <HeroContainer backgroundImage={backgroundImage}>
      <HeroContent>
        <Logo src={LogoBridge} alt="Nunner Logo" loading="lazy" />
      </HeroContent>
      <HeroActions>
        <QuoteContainer>
          <h2>{t('WHERE DO YOU WANT YOUR GOODS TO GO?')}</h2>
          <ActionButton to="/quotation">{t('GET A QUOTE')}</ActionButton>
        </QuoteContainer>
        <TrackContainer>
          <h2>{t('HOW CAN OUR SERVICES OPTIMIZE YOUR LOGISTICS?')}</h2>
          <ActionButton to="/Services">{t('CHECK OUR SERVICES')}</ActionButton>
        </TrackContainer>
      </HeroActions>
    </HeroContainer>
  );
};

export default Hero;

const HeroContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  padding-inline: 50px;
  color: white;
  direction: ${(props) => (props.dir === 'rtl' ? 'rtl' : 'ltr')};

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
`;

const HeroContent = styled.div`
  flex: 2;

  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  height: 120px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const HeroActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

const QuoteContainer = styled.div`
  background-color: rgba(255, 165, 0, 0.9);
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;

  h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      text-align: center;
    }
  }
`;

const TrackContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  text-align: left;
  width: 100%;

  h2 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;

    @media (max-width: 768px) {
      font-size: 1.2rem;
      text-align: center;
    }
  }
`;

const ActionButton = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: #003a70;
  color: white;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #ffcc00;
    color: #003a70;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;
