import React from 'react';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Footer = () => {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <FooterContainer>
      <FooterContent>
        <FooterTop>
          <Logo>{t('Bridge Logistics')}</Logo>
          <SocialLinks>
            <SocialLink href="#">
              <FaFacebookF />
            </SocialLink>
            <SocialLink href="#">
              <FaTwitter />
            </SocialLink>
            <SocialLink href="#">
              <FaLinkedinIn />
            </SocialLink>
            <SocialLink href="#">
              <FaInstagram />
            </SocialLink>
          </SocialLinks>
        </FooterTop>
        <FooterMiddle>
          <Column>
            <ColumnTitle>{t('Team')}</ColumnTitle>
            <StyledLink to="/team">{t('Meet the Team')}</StyledLink>
            <StyledLink to="/apply">{t('Join Us')}</StyledLink>
          </Column>
          <Column>
            <ColumnTitle>{t('Locations')}</ColumnTitle>
            <StyledLink to="/location">{t('Our Offices')}</StyledLink>
            <StyledLink to="/contact">{t('Contact a Location')}</StyledLink>
          </Column>
          <Column>
            <ColumnTitle>{t('Contact')}</ColumnTitle>
            <ContactInfo>{t('Hazmieh, Saint Rock Centre, Ground Floor, Beirut - Lebanon')}</ContactInfo>
            <ContactInfo>{t('Phone')}: +961 5 951 171 - Ext 21</ContactInfo>
            <ContactInfo>{t('Email')}: info@bridgelog.net</ContactInfo>
          </Column>
          <Column>
            <ColumnTitle>{t('Newsletter')}</ColumnTitle>
            <NewsletterForm>
              <Input type="email" placeholder={t('Your email address')} />
              <SubscribeButton>{t('Subscribe')}</SubscribeButton>
            </NewsletterForm>
          </Column>
        </FooterMiddle>
        <FooterBottom>
          <p>© 2024 {t('Bridge Logistics')}. {t('All rights reserved.')}</p>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

const FooterContainer = styled.footer`
  background-color: #003a70;
  padding: 20px 15px;
  color: white;
  direction: ltr; /* Enforce LTR direction */
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 10px;
`;

const SocialLink = styled.a`
  color: white;
  font-size: 1rem;
  transition: color 0.3s ease;

  &:hover {
    color: #f7931e;
  }
`;

const FooterMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const Column = styled.div`
  flex: 1 1 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 100px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const ColumnTitle = styled.h3`
  margin-bottom: 8px;
  font-size: 1rem;
  color: #f7931e;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const StyledLink = styled(RouterLink)`
  color: white;
  margin-bottom: 6px;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #f7931e;
  }

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const ContactInfo = styled.p`
  margin: 0;
  margin-bottom: 6px;
  font-size: 0.85rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 250px;
  }
`;

const Input = styled.input`
  padding: 8px;
  border-radius: 5px;
  border: none;
  width: 100%;
  max-width: 200px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const SubscribeButton = styled.button`
  padding: 8px;
  background-color: #f7931e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    padding: 6px;
  }
`;

const FooterBottom = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  text-align: center;
  width: 100%;

  p {
    margin: 0;
    font-size: 0.8rem;
  }
`;
