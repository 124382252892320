// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector) // Automatically detects user language
  .use(initReactI18next)  // Passes i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "Locations": "Locations",
          "Contact": "Contact",
          "search_here": "Search here...",
          "Home": "Home",
          "Discover us": "Discover us",
          "Industry": "Industry",
          "Services": "Services",
          "Team": "Team",
          "WHERE DO YOU WANT YOUR GOODS TO GO?": "WHERE DO YOU WANT YOUR GOODS TO GO?",
          "GET A QUOTE": "GET A QUOTE",
          "HOW CAN OUR SERVICES OPTIMIZE YOUR LOGISTICS?": "HOW CAN OUR SERVICES OPTIMIZE YOUR LOGISTICS?",
          "CHECK OUR SERVICES": "CHECK OUR SERVICES",
          "NOBODY GOES FURTHER": "NOBODY GOES FURTHER",
          "DISCOVER": "DISCOVER",
          "Bridge Logistics Services": "Bridge Logistics Services",
          "Discover how nobody goes further to get your goods where they have to go. On time. Every time.": "Discover how nobody goes further to get your goods where they have to go. On time. Every time.",
          "DISCOVER OUR": "DISCOVER OUR",
          "SPECIALIST KNOW HOW": "SPECIALIST KNOW HOW",
          "Profit from our in-depth experience and specialist knowhow.": "Profit from our in-depth experience and specialist knowhow.",
          "SPECIAL ADDED VALUE IN INDUSTRY": "SPECIAL ADDED VALUE IN INDUSTRY",
          "Learn how we can contribute to your bottom line.": "Learn how we can contribute to your bottom line.",
          "Read more": "Read more",
          "VALUE INNOVATION": "VALUE INNOVATION",
          "At Bridge Logistics, we are constantly seeking the best logistics solutions tailored to your needs. We embrace the concept of ‘value innovation,’ striving to deliver more while maintaining cost-efficiency. We take great pride in caring for our people, partners, customers, and stakeholders, all while committing to meeting stringent sustainability standards.": "At Bridge Logistics, we are constantly seeking the best logistics solutions tailored to your needs. We embrace the concept of ‘value innovation,’ striving to deliver more while maintaining cost-efficiency. We take great pride in caring for our people, partners, customers, and stakeholders, all while committing to meeting stringent sustainability standards.",
          "NEWS": "NEWS",
          "CEO'S BLOG": "CEO'S BLOG",
          "WANT TO BE A": "WANT TO BE A",
          "HAULIER?": "HAULIER?",
          "WATCH NOBODY GOES FURTHER": "WATCH NOBODY GOES FURTHER",
          "ONE BELT, ONE ROAD...": "ONE BELT, ONE ROAD...",
          "CONNECTING CHINA TO THE WORLD": "CONNECTING CHINA TO THE WORLD",
          "A first-ever freight train filled with Dutch-made Machinery, Mineral fuels...": "A first-ever freight train filled with Dutch-made Machinery, Mineral fuels...",
          "APPLY NOW": "APPLY NOW",
          "MISSION STATEMENT": "MISSION STATEMENT",
          "PROVIDE LEADING LOGISTICS SOLUTIONS TO CONTINUOUSLY IMPROVE CUSTOMERS' GLOBAL COMPETITIVENESS WITH RESPECT FOR PEOPLE AND PLANET.": "PROVIDE LEADING LOGISTICS SOLUTIONS TO CONTINUOUSLY IMPROVE CUSTOMERS' GLOBAL COMPETITIVENESS WITH RESPECT FOR PEOPLE AND PLANET.",
          "ABOUT Bridge Logistics": "ABOUT Bridge Logistics",
          "Bridge Logistics is your trusted partner in navigating the complexities of the global supply chain. With over two decades of expertise, we excel in orchestrating seamless transportation solutions for your cargo, spanning international borders and markets. As active members of prestigious industry associations such as the Lebanese Forwarders Association, PCN, and ALN, we maintain a strong presence in the global logistics landscape. Our accreditation from the Ministry of Transportation in Lebanon underscores our unwavering commitment to adhering to industry regulations and delivering superior service.": "Bridge Logistics is your trusted partner in navigating the complexities of the global supply chain. With over two decades of expertise, we excel in orchestrating seamless transportation solutions for your cargo, spanning international borders and markets. As active members of prestigious industry associations such as the Lebanese Forwarders Association, PCN, and ALN, we maintain a strong presence in the global logistics landscape. Our accreditation from the Ministry of Transportation in Lebanon underscores our unwavering commitment to adhering to industry regulations and delivering superior service.",
          "At Bridge Logistics, we specialize in cross-trade shipments within Lebanon and the Middle East, leveraging our extensive network and strategic partnerships to optimize supply chain efficiency. Our comprehensive approach encompasses meticulous planning, streamlined execution, and proactive risk management to ensure your goods' timely and secure delivery.": "At Bridge Logistics, we specialize in cross-trade shipments within Lebanon and the Middle East, leveraging our extensive network and strategic partnerships to optimize supply chain efficiency. Our comprehensive approach encompasses meticulous planning, streamlined execution, and proactive risk management to ensure your goods' timely and secure delivery.",
          "Beyond our core logistics operations, we foster collaboration and business growth through our affiliations with the Lebanese Dutch & Belgium Business Association and key chambers of commerce in Europe. These partnerships facilitate faultless trade connections and open doors to new opportunities for our clients in the global marketplace. Bridge Logistics is your gateway to tailored logistics solutions that drive your business forward. Experience the intersection of expertise, innovation, and dedication as we embark on a journey to optimize your supply chain and maximize your success.": "Beyond our core logistics operations, we foster collaboration and business growth through our affiliations with the Lebanese Dutch & Belgium Business Association and key chambers of commerce in Europe. These partnerships facilitate faultless trade connections and open doors to new opportunities for our clients in the global marketplace. Bridge Logistics is your gateway to tailored logistics solutions that drive your business forward. Experience the intersection of expertise, innovation, and dedication as we embark on a journey to optimize your supply chain and maximize your success.",
          "Chemicals & Agribusiness": "Chemicals & Agribusiness",
          "High Tech and Telecommunications": "High Tech and Telecommunications",
          "Health Care and Pharmaceutical": "Health Care and Pharmaceutical",
          "Medical Equipment": "Medical Equipment",
          "Industrial Products": "Industrial Products",
          "Live Stock": "Live Stock",
          "Liquor and Cigarettes": "Liquor and Cigarettes",
          "Automotive and Sports": "Automotive and Sports",
          "Events and Exhibition": "Events and Exhibition",
          "Air Charter": "Air Charter",
          "Projects": "Projects",
          "Perishables": "Perishables",
          "Bridge Logistics stands out as a trusted partner in the world of logistics, providing specialized services across a wide spectrum of industries. Discover how we excel in delivering tailored solutions for your specific needs:": "Bridge Logistics stands out as a trusted partner in the world of logistics, providing specialized services across a wide spectrum of industries. Discover how we excel in delivering tailored solutions for your specific needs:",
          "In the chemicals and agribusiness sectors, safety and compliance are paramount. Our logistics solutions prioritize the secure transportation of hazardous materials and agricultural products, ensuring regulatory adherence and operational efficiency.": "In the chemicals and agribusiness sectors, safety and compliance are paramount. Our logistics solutions prioritize the secure transportation of hazardous materials and agricultural products, ensuring regulatory adherence and operational efficiency.",
          "In the fast-paced high-tech and telecommunications industries, precision and speed are non-negotiable. We offer agile logistics support to meet tight deadlines and handle sensitive electronic components with utmost care.": "In the fast-paced high-tech and telecommunications industries, precision and speed are non-negotiable. We offer agile logistics support to meet tight deadlines and handle sensitive electronic components with utmost care.",
          "The health care and pharmaceutical sectors demand impeccable logistics execution. We excel in handling pharmaceuticals, medical supplies, and equipment with strict adherence to regulatory standards and temperature-sensitive requirements.": "The health care and pharmaceutical sectors demand impeccable logistics execution. We excel in handling pharmaceuticals, medical supplies, and equipment with strict adherence to regulatory standards and temperature-sensitive requirements.",
          "Transporting delicate medical equipment requires specialized expertise. We provide dedicated logistics solutions to ensure the safe and timely delivery of vital medical devices, supporting health care providers worldwide.": "Transporting delicate medical equipment requires specialized expertise. We provide dedicated logistics solutions to ensure the safe and timely delivery of vital medical devices, supporting health care providers worldwide.",
          "For manufacturers of industrial products, efficiency and reliability are key. Our logistics services optimize supply chain management for heavy machinery, equipment parts, and industrial components.": "For manufacturers of industrial products, efficiency and reliability are key. Our logistics services optimize supply chain management for heavy machinery, equipment parts, and industrial components.",
          "We understand the unique challenges of transporting live animals. Our livestock logistics ensure the humane and safe movement of livestock, catering to the needs of the agriculture and farming industries.": "We understand the unique challenges of transporting live animals. Our livestock logistics ensure the humane and safe movement of livestock, catering to the needs of the agriculture and farming industries.",
          "Secure and compliant transportation is essential for liquor and tobacco products. We offer specialized logistics solutions to handle these sensitive goods, guaranteeing timely delivery while meeting regulatory requirements.": "Secure and compliant transportation is essential for liquor and tobacco products. We offer specialized logistics solutions to handle these sensitive goods, guaranteeing timely delivery while meeting regulatory requirements.",
          "Whether it's vehicles or sports equipment, we provide comprehensive logistics services tailored to the automotive and sports industries. Our solutions prioritize the protection and efficient delivery of valuable assets.": "Whether it's vehicles or sports equipment, we provide comprehensive logistics services tailored to the automotive and sports industries. Our solutions prioritize the protection and efficient delivery of valuable assets.",
          "Planning an event or exhibition? Count on us for end-to-end logistics support. We manage the transportation, setup, and coordination of event materials to ensure a seamless experience.": "Planning an event or exhibition? Count on us for end-to-end logistics support. We manage the transportation, setup, and coordination of event materials to ensure a seamless experience.",
          "When urgency is paramount, our air charter services deliver flexible and efficient transportation solutions. We facilitate urgent or specialized cargo movements, providing swift and reliable air transportation.": "When urgency is paramount, our air charter services deliver flexible and efficient transportation solutions. We facilitate urgent or specialized cargo movements, providing swift and reliable air transportation.",
          "Complex projects demand meticulous logistics planning. Our project logistics expertise ensures the smooth execution of large-scale movements, from inception to completion.": "Complex projects demand meticulous logistics planning. Our project logistics expertise ensures the smooth execution of large-scale movements, from inception to completion.",
          "Maintaining product quality during transit is critical for perishable goods. Our temperature-controlled logistics solutions preserve freshness and extend shelf life for perishable items, including fresh produce and seafood.": "Maintaining product quality during transit is critical for perishable goods. Our temperature-controlled logistics solutions preserve freshness and extend shelf life for perishable items, including fresh produce and seafood.",
          "Ocean Freight": "Ocean Freight",
          "Air Freight": "Air Freight",
          "Land Transportation": "Land Transportation",
          "Customs Clearance": "Customs Clearance",
          "Warehousing & Distribution": "Warehousing & Distribution",
          "Our ocean freight solutions provide reliable and cost-efficient options for shipping goods internationally. Whether you need Full Container Load (FCL) or Less than Container Load (LCL) shipments, we ensure punctual transit times and competitive pricing.": "Our ocean freight solutions provide reliable and cost-efficient options for shipping goods internationally. Whether you need Full Container Load (FCL) or Less than Container Load (LCL) shipments, we ensure punctual transit times and competitive pricing.",
          "Our air freight services offer expedited shipping solutions when time sensitivity is critical. We expertly handle urgent and time-critical shipments, ensuring swift and secure transport to global destinations.": "Our air freight services offer expedited shipping solutions when time sensitivity is critical. We expertly handle urgent and time-critical shipments, ensuring swift and secure transport to global destinations.",
          "Benefit from land transportation services connecting your supply chain across regions. Our extensive fleet and partner network guarantee prompt and dependable delivery for local distribution or cross-border operations.": "Benefit from land transportation services connecting your supply chain across regions. Our extensive fleet and partner network guarantee prompt and dependable delivery for local distribution or cross-border operations.",
          "Our expertise is in navigating complex customs regulations. Our seasoned customs clearance team manages all import and export documentation facets, ensuring adherence to rules and smooth clearance at border crossings.": "Our expertise is in navigating complex customs regulations. Our seasoned customs clearance team manages all import and export documentation facets, ensuring adherence to rules and smooth clearance at border crossings.",
          "Utilize our state-of-the-art warehousing facilities strategically positioned to support your storage and distribution needs. From inventory optimization to order fulfillment, we provide flexible warehousing solutions tailored to your business.": "Utilize our state-of-the-art warehousing facilities strategically positioned to support your storage and distribution needs. From inventory optimization to order fulfillment, we provide flexible warehousing solutions tailored to your business.",
          "CEO": "CEO",
          "Accounting Manager": "Accounting Manager",
          "Assistant Commercial Manager": "Assistant Commercial Manager",
          "Sales Supervisor": "Sales Supervisor",
          "Sales Executives": "Sales Executives",
          "Meet Our": "Meet Our",
          
          "GabyDescription": "Gaby El Azzi, our CEO, brings over 30 years of expertise in the logistics industry, demonstrating visionary leadership and strategic insight at Bridge Logistics. His deep knowledge and commitment to excellence drive innovation and growth, consistently delivering exceptional results for our clients and stakeholders.",
          "JosephDescription": "Joseph El Jurdy oversees our financial operations with precision and expertise. As Accounting Manager, he ensures that our financial processes are efficient and transparent, providing the backbone for our business success.",
          "PierreDescription": "Pierre-Nadim Aoun assists in leading our commercial activities, forging strong partnerships and ensuring customer satisfaction. With a focus on market trends and customer needs, Pierre drives our commercial strategies forward.",
          "AntoinetteDescription": "Antoinette Atallah plays a key role in our sales team as the Sales Supervisor. She leads by example, motivating and guiding our sales executives to achieve exceptional results and foster lasting client relationships.",
          "ElieMikelDescription": "Our dynamic duo of Sales Executives—Elie Farhat and Mikel Kassouf—bring energy and expertise to our sales operations. They are dedicated to delivering tailored solutions that meet our clients' logistics needs.",
        }

      },
      ar: {
        translation: {
          "Locations": "مواقع",
          "Contact": "اتصل",
          "locations":"مواقع",
          "contact":"اتصل",
          "search_here": "ابحث هنا...",
          "Home": "الصفحة الرئيسية",
          "Discover us": "اكتشفنا",
          "Industry": "الصناعة",
          "INDUSTRY":"الصناعة",
          "Services": "الخدمات",
          "SERVICES": "الخدمات",
          "About Bridge Logistics":"اكتشف بريدج لوجيستك",
          "Team": "الفريق",
          "WHERE DO YOU WANT YOUR GOODS TO GO?": "إلى أين تريد إرسال بضائعك؟",
          "GET A QUOTE": "احصل على عرض سعر",
          "HOW CAN OUR SERVICES OPTIMIZE YOUR LOGISTICS?": "كيف يمكن لخدماتنا تحسين لوجستياتك؟",
          "CHECK OUR SERVICES": "اطلع على خدماتنا",
          "NOBODY GOES FURTHER": "لا أحد يذهب أبعد",
          "DISCOVER": "اكتشف",
          "Bridge Logistics Services": "خدمات بريدج اللوجستية",
          "Discover how nobody goes further to get your goods where they have to go. On time. Every time.": "اكتشف كيف لا أحد يذهب أبعد للحصول على بضائعك حيث يجب أن تذهب. في الوقت المحدد. في كل مرة.",
          "DISCOVER OUR": "اكتشف",
          "SPECIALIST KNOW HOW": "معرفة الخبراء",
          "Profit from our in-depth experience and specialist knowhow.": "استفد من خبرتنا العميقة ومعرفة الخبراء.",
          "SPECIAL ADDED VALUE IN INDUSTRY": "القيمة المضافة الخاصة في الصناعة",
          "Learn how we can contribute to your bottom line.": "تعرف على كيف يمكننا المساهمة في تحسين النتائج المالية لديك.",
          "Read more": "اقرأ المزيد",
          "VALUE INNOVATION": "ابتكار القيمة",
          "At Bridge Logistics, we are constantly seeking the best logistics solutions tailored to your needs. We embrace the concept of ‘value innovation,’ striving to deliver more while maintaining cost-efficiency. We take great pride in caring for our people, partners, customers, and stakeholders, all while committing to meeting stringent sustainability standards.": "في بريدج لوجيستيكس، نسعى باستمرار للحصول على أفضل الحلول اللوجستية المخصصة لاحتياجاتك. نتبنى مفهوم 'ابتكار القيمة'، ونسعى لتقديم المزيد مع الحفاظ على الكفاءة في التكلفة. نفخر كثيرًا بالاهتمام بأفرادنا وشركائنا وعملائنا وأصحاب المصلحة، بينما نلتزم بمعايير الاستدامة الصارمة.",
          "NEWS": "أخبار",
          "CEO'S BLOG": "مدونة الرئيس التنفيذي",
          "WANT TO BE A": "هل تريد أن تكون",
          "HAULIER?": "ناقل؟",
          "WATCH NOBODY GOES FURTHER": "شاهد لا أحد يذهب أبعد",
          "ONE BELT, ONE ROAD...": "حزام واحد، طريق واحد...",
          "CONNECTING CHINA TO THE WORLD": "ربط الصين بالعالم",
          "A first-ever freight train filled with Dutch-made Machinery, Mineral fuels...": "أول قطار شحن مليء بالآلات الهولندية الصنع، الوقود المعدني...",
          "APPLY NOW": "قدم الآن",
          "MISSION STATEMENT": "بيان المهمة",
          "PROVIDE LEADING LOGISTICS SOLUTIONS TO CONTINUOUSLY IMPROVE CUSTOMERS' GLOBAL COMPETITIVENESS WITH RESPECT FOR PEOPLE AND PLANET.": "تقديم حلول لوجستية رائدة لتحسين القدرة التنافسية العالمية للعملاء باستمرار مع احترام الإنسان والكوكب.",
          "ABOUT Bridge Logistics": "عن بريدج لوجيستيكس",
          "Bridge Logistics is your trusted partner in navigating the complexities of the global supply chain. With over two decades of expertise, we excel in orchestrating seamless transportation solutions for your cargo, spanning international borders and markets. As active members of prestigious industry associations such as the Lebanese Forwarders Association, PCN, and ALN, we maintain a strong presence in the global logistics landscape. Our accreditation from the Ministry of Transportation in Lebanon underscores our unwavering commitment to adhering to industry regulations and delivering superior service.": "بريدج لوجيستيكس هو شريكك الموثوق في التنقل عبر تعقيدات سلسلة التوريد العالمية. مع أكثر من عقدين من الخبرة، نتفوق في تنظيم حلول النقل السلسة لشحنتك، الممتدة عبر الحدود والأسواق الدولية. كأعضاء نشطين في جمعيات صناعية مرموقة مثل جمعية وكلاء الشحن اللبنانيين و PCN و ALN، نحافظ على حضور قوي في المشهد اللوجستي العالمي. إن اعتمادنا من وزارة النقل في لبنان يؤكد التزامنا الثابت بالامتثال للوائح الصناعة وتقديم خدمة ممتازة.",
          "At Bridge Logistics, we specialize in cross-trade shipments within Lebanon and the Middle East, leveraging our extensive network and strategic partnerships to optimize supply chain efficiency. Our comprehensive approach encompasses meticulous planning, streamlined execution, and proactive risk management to ensure your goods' timely and secure delivery.": "في بريدج لوجيستيكس، نحن متخصصون في شحنات التجارة العابرة في لبنان والشرق الأوسط، ونستفيد من شبكتنا الواسعة والشراكات الاستراتيجية لتحسين كفاءة سلسلة التوريد. تشمل منهجيتنا الشاملة التخطيط الدقيق والتنفيذ المبسط وإدارة المخاطر الاستباقية لضمان التسليم في الوقت المناسب والآمن لبضائعك.",
          "Beyond our core logistics operations, we foster collaboration and business growth through our affiliations with the Lebanese Dutch & Belgium Business Association and key chambers of commerce in Europe. These partnerships facilitate faultless trade connections and open doors to new opportunities for our clients in the global marketplace. Bridge Logistics is your gateway to tailored logistics solutions that drive your business forward. Experience the intersection of expertise, innovation, and dedication as we embark on a journey to optimize your supply chain and maximize your success.": "إلى جانب عملياتنا اللوجستية الأساسية، نشجع التعاون والنمو التجاري من خلال ارتباطاتنا مع الجمعية التجارية اللبنانية الهولندية والبلجيكية وغرف التجارة الرئيسية في أوروبا. تسهل هذه الشراكات روابط التجارة الخالية من الأخطاء وتفتح الأبواب أمام فرص جديدة لعملائنا في السوق العالمية. بريدج لوجيستيكس هو بوابتك لحلول لوجستية مخصصة تدفع عملك للأمام. اكتشف التقاء الخبرة والابتكار والالتزام بينما نشرع في رحلة لتحسين سلسلة التوريد الخاصة بك وزيادة نجاحك.",
          "Chemicals & Agribusiness": "الكيماويات والأعمال الزراعية",
          "High Tech and Telecommunications": "التكنولوجيا الفائقة والاتصالات",
          "Health Care and Pharmaceutical": "الرعاية الصحية والأدوية",
          "Medical Equipment": "المعدات الطبية",
          "Industrial Products": "المنتجات الصناعية",
          "Live Stock": "الماشية",
          "Liquor and Cigarettes": "المشروبات الكحولية والسجائر",
          "Automotive and Sports": "السيارات والرياضة",
          "Events and Exhibition": "الأحداث والمعارض",
          "Air Charter": "التأجير الجوي",
          "Projects": "المشاريع",
          "Perishables": "البضائع القابلة للتلف",
          "Bridge Logistics stands out as a trusted partner in the world of logistics, providing specialized services across a wide spectrum of industries. Discover how we excel in delivering tailored solutions for your specific needs:": "بريدج لوجيستيكس تبرز كشريك موثوق في عالم اللوجستيات، حيث تقدم خدمات متخصصة عبر مجموعة واسعة من الصناعات. اكتشف كيف نتميز في تقديم حلول مخصصة لاحتياجاتك الخاصة:",
          "In the chemicals and agribusiness sectors, safety and compliance are paramount. Our logistics solutions prioritize the secure transportation of hazardous materials and agricultural products, ensuring regulatory adherence and operational efficiency.": "في قطاعات الكيماويات والأعمال الزراعية، السلامة والامتثال لهما أهمية قصوى. تركز حلولنا اللوجستية على النقل الآمن للمواد الخطرة والمنتجات الزراعية، مما يضمن الامتثال التنظيمي والكفاءة التشغيلية.",
          "In the fast-paced high-tech and telecommunications industries, precision and speed are non-negotiable. We offer agile logistics support to meet tight deadlines and handle sensitive electronic components with utmost care.": "في الصناعات ذات التقنية العالية والاتصالات السريعة الإيقاع، الدقة والسرعة أمران غير قابلين للتفاوض. نقدم دعمًا لوجستيًا مرنًا لتلبية المواعيد النهائية الضيقة ومعالجة المكونات الإلكترونية الحساسة بأقصى قدر من العناية.",
          "The health care and pharmaceutical sectors demand impeccable logistics execution. We excel in handling pharmaceuticals, medical supplies, and equipment with strict adherence to regulatory standards and temperature-sensitive requirements.": "تتطلب قطاعات الرعاية الصحية والأدوية تنفيذًا لوجستيًا لا تشوبه شائبة. نحن نتفوق في التعامل مع الأدوية والإمدادات الطبية والمعدات مع الالتزام الصارم بالمعايير التنظيمية والمتطلبات الحساسة للحرارة.",
          "Transporting delicate medical equipment requires specialized expertise. We provide dedicated logistics solutions to ensure the safe and timely delivery of vital medical devices, supporting health care providers worldwide.": "يتطلب نقل المعدات الطبية الحساسة خبرة متخصصة. نقدم حلولاً لوجستية مخصصة لضمان التوصيل الآمن وفي الوقت المناسب للأجهزة الطبية الحيوية، مما يدعم مقدمي الرعاية الصحية في جميع أنحاء العالم.",
          "For manufacturers of industrial products, efficiency and reliability are key. Our logistics services optimize supply chain management for heavy machinery, equipment parts, and industrial components.": "بالنسبة لمصنعي المنتجات الصناعية، فإن الكفاءة والموثوقية هما الأساس. خدماتنا اللوجستية تعمل على تحسين إدارة سلسلة التوريد للآلات الثقيلة وأجزاء المعدات والمكونات الصناعية.",
          "We understand the unique challenges of transporting live animals. Our livestock logistics ensure the humane and safe movement of livestock, catering to the needs of the agriculture and farming industries.": "نحن نفهم التحديات الفريدة لنقل الحيوانات الحية. تضمن لوجستياتنا للماشية الحركة الإنسانية والآمنة للماشية، مما يلبي احتياجات صناعات الزراعة والتربية.",
          "Secure and compliant transportation is essential for liquor and tobacco products. We offer specialized logistics solutions to handle these sensitive goods, guaranteeing timely delivery while meeting regulatory requirements.": "النقل الآمن والمتوافق أمر ضروري للمنتجات الكحولية والتبغ. نحن نقدم حلول لوجستية متخصصة للتعامل مع هذه البضائع الحساسة، مما يضمن التسليم في الوقت المناسب مع تلبية المتطلبات التنظيمية.",
          "Whether it's vehicles or sports equipment, we provide comprehensive logistics services tailored to the automotive and sports industries. Our solutions prioritize the protection and efficient delivery of valuable assets.": "سواء كانت المركبات أو المعدات الرياضية، نقدم خدمات لوجستية شاملة مخصصة لصناعات السيارات والرياضة. تركز حلولنا على حماية وتسليم الأصول القيمة بكفاءة.",
          "Planning an event or exhibition? Count on us for end-to-end logistics support. We manage the transportation, setup, and coordination of event materials to ensure a seamless experience.": "تخطط لحدث أو معرض؟ اعتمد علينا في تقديم الدعم اللوجستي من البداية إلى النهاية. نحن ندير النقل، الإعداد، وتنسيق مواد الحدث لضمان تجربة سلسة.",
          "When urgency is paramount, our air charter services deliver flexible and efficient transportation solutions. We facilitate urgent or specialized cargo movements, providing swift and reliable air transportation.": "عندما تكون السرعة أمرًا بالغ الأهمية، تقدم خدمات التأجير الجوي لدينا حلول نقل مرنة وفعالة. نسهل حركات الشحن العاجلة أو المتخصصة، مما يوفر النقل الجوي السريع والموثوق.",
          "Complex projects demand meticulous logistics planning. Our project logistics expertise ensures the smooth execution of large-scale movements, from inception to completion.": "تتطلب المشاريع المعقدة تخطيطًا لوجستيًا دقيقًا. يضمن خبرتنا في لوجستيات المشاريع التنفيذ السلس للحركات الكبيرة، من البداية إلى النهاية.",
          "Maintaining product quality during transit is critical for perishable goods. Our temperature-controlled logistics solutions preserve freshness and extend shelf life for perishable items, including fresh produce and seafood.": "الحفاظ على جودة المنتج أثناء النقل أمر حيوي للبضائع القابلة للتلف. حلولنا اللوجستية المتحكم فيها بالحرارة تحافظ على النضارة وتطيل العمر الافتراضي للبضائع القابلة للتلف، بما في ذلك المنتجات الطازجة والمأكولات البحرية.",
          "Ocean Freight": "الشحن البحري",
          "Air Freight": "الشحن الجوي",
          "Land Transportation": "النقل البري",
          "Customs Clearance": "تخليص جمركي",
          "Warehousing & Distribution": "التخزين والتوزيع",
          "Our ocean freight solutions provide reliable and cost-efficient options for shipping goods internationally. Whether you need Full Container Load (FCL) or Less than Container Load (LCL) shipments, we ensure punctual transit times and competitive pricing.": "توفر حلول الشحن البحري لدينا خيارات موثوقة وفعالة من حيث التكلفة لشحن البضائع دوليًا. سواء كنت تحتاج إلى شحن حاويات كاملة (FCL) أو شحنات أقل من الحاويات (LCL)، نضمن أوقات عبور دقيقة وتسعير تنافسي.",
          "Our air freight services offer expedited shipping solutions when time sensitivity is critical. We expertly handle urgent and time-critical shipments, ensuring swift and secure transport to global destinations.": "توفر خدمات الشحن الجوي لدينا حلول شحن سريعة عندما يكون الوقت حاسمًا. نحن نتعامل بخبرة مع الشحنات العاجلة والحرجة من حيث الوقت، مما يضمن النقل السريع والآمن إلى الوجهات العالمية.",
          "Benefit from land transportation services connecting your supply chain across regions. Our extensive fleet and partner network guarantee prompt and dependable delivery for local distribution or cross-border operations.": "استفد من خدمات النقل البري التي تربط سلسلة التوريد الخاصة بك عبر المناطق. يضمن أسطولنا الواسع وشبكة شركائنا التسليم السريع والموثوق للتوزيع المحلي أو العمليات عبر الحدود.",
          "Our expertise is in navigating complex customs regulations. Our seasoned customs clearance team manages all import and export documentation facets, ensuring adherence to rules and smooth clearance at border crossings.": "تتمثل خبرتنا في التنقل عبر اللوائح الجمركية المعقدة. يدير فريق التخليص الجمركي المخضرم لدينا جميع جوانب وثائق الاستيراد والتصدير، مما يضمن الالتزام بالقواعد والتخليص السلس عند المعابر الحدودية.",
          "Utilize our state-of-the-art warehousing facilities strategically positioned to support your storage and distribution needs. From inventory optimization to order fulfillment, we provide flexible warehousing solutions tailored to your business.": "استخدم مرافق التخزين الحديثة لدينا، التي تم وضعها استراتيجيًا لدعم احتياجات التخزين والتوزيع الخاصة بك. من تحسين المخزون إلى تلبية الطلبات، نقدم حلول تخزين مرنة مصممة خصيصًا لعملك.",
          "CEO": "المدير التنفيذي",
          "Accounting Manager": "مدير المحاسبة",
          "Assistant Commercial Manager": "مساعد مدير الشؤون التجارية",
          "Sales Supervisor": "مشرف مبيعات",
          "Sales Executives": "تنفيذيي المبيعات",
          "Meet Our": "تعرف على ",
          

          // Team member descriptions
          "GabyDescription": "غابي العزي، مديرنا التنفيذي، يتمتع بأكثر من 30 عامًا من الخبرة في صناعة اللوجستيات، ويظهر قيادة ذات رؤية وبصيرة استراتيجية في بريدج لوجيستيكس. معرفته العميقة والتزامه بالتميز يدفعان الابتكار والنمو، ويحققان باستمرار نتائج استثنائية لعملائنا وأصحاب المصلحة.",
          "JosephDescription": "جوزيف الجردي يشرف على عملياتنا المالية بدقة وخبرة. بصفته مدير المحاسبة، يضمن أن تكون عملياتنا المالية فعالة وشفافة، مما يشكل العمود الفقري لنجاح أعمالنا.",
          "PierreDescription": "بيير-نديم عون يساعد في قيادة أنشطتنا التجارية، ويعمل على بناء شراكات قوية وضمان رضا العملاء. من خلال التركيز على اتجاهات السوق واحتياجات العملاء، يدفع بيير استراتيجياتنا التجارية إلى الأمام.",
          "AntoinetteDescription": "أنطوانيت عطاالله تلعب دورًا رئيسيًا في فريق المبيعات لدينا كمشرفة مبيعات. تقود بالقدوة، وتلهم وتوجه منفذي المبيعات لدينا لتحقيق نتائج استثنائية وبناء علاقات دائمة مع العملاء.",
          "ElieMikelDescription": "ثنائي المبيعات الديناميكي لدينا - إيلي فرحات وميكل قسوف - يجلبان الطاقة والخبرة لعمليات المبيعات لدينا. إنهما ملتزمان بتقديم حلول مخصصة تلبي احتياجات عملائنا في اللوجستيات.",
          "Our Offices": "مكاتبنا",
    "Contact a Location": "اتصل بموقع",
    "Meet the Team": "تعرف على الفريق",
    "Join Us": "انضم إلينا",
    
  
    "Newsletter": "النشرة الإخبارية",
    "Your email address": "عنوان بريدك الإلكتروني",
    "Subscribe": "اشترك",
    "All rights reserved.": "جميع الحقوق محفوظة.",
    "CONTACT US": "اتصل بنا",
    "Reach out to us for any inquiries, support, or feedback. We are here to assist you with your logistics needs.": "تواصل معنا لأي استفسارات أو دعم أو ملاحظات. نحن هنا لمساعدتك في تلبية احتياجاتك اللوجستية.",
    "Your Name *": "اسمك *",
    "Your Email *": "بريدك الإلكتروني *",
    "Subject": "الموضوع",
    "Your Message": "رسالتك",
    "SEND MESSAGE": "أرسل الرسالة",
    "Hazmieh, Saint Rock Centre, Ground Floor, Beirut - Lebanon": "حازمية، مركز سانت روك، الطابق الأرضي، بيروت - لبنان",
    "Phone": "هاتف",
    "Email": "البريد الإلكتروني",
    "QUOTATION": "عرض أسعار",
    "At Bridge Logistics we want to give you the best possible value for money experience. Please fill in this form to start the process.": "في بريدج لوجيستيكس، نرغب في تقديم أفضل قيمة ممكنة مقابل المال. يرجى ملء هذا النموذج لبدء العملية.",
    "Company name *": "اسم الشركة *",
    "Email *": "البريد الإلكتروني *",
    "WHERE FROM:": "من أين:",
    "Country of origin *": "بلد المنشأ *",
    "Zip code and city of origin *": "الرمز البريدي ومدينة المنشأ *",
    "WHERE TO:": "إلى أين:",
    "Country of destination *": "بلد الوجهة *",
    "Zip code and city of destination *": "الرمز البريدي ومدينة الوجهة *",
    "DESCRIPTION OF GOODS:": "وصف البضائع:",
    "Commodity *": "السلعة *",
    "Nr and type of packages": "عدد ونوع الطرود",
    "Gross weight": "الوزن الإجمالي",
    "Comments": "التعليقات",
    
    "APPLY FOR A JOB AT Bridge Logistics": "التقدم لوظيفة في بريدج لوجيستيكس",
    "First name": "الاسم الأول",
    "Last name *": "اسم العائلة *",
    
    "Phone *": "الهاتف *",
    "Motivation": "الدافع",
    "File Upload (pdf only)": "تحميل الملف (PDF فقط)",
    "Choose File": "اختر ملف",
    "No file chosen": "لم يتم اختيار ملف",
    "SEND": "إرسال",
    "Bridge Logistics Logo": "شعار بريدج لوجيستيكس",
    "Our comprehensive logistics services provide you with the best solutions for your supply chain needs, ensuring that your goods are transported safely, efficiently, and cost-effectively.":"خدماتنا اللوجستية الشاملة توفر لك أفضل الحلول لاحتياجات سلسلة التوريد الخاصة بك، مما يضمن نقل بضائعك بأمان وكفاءة وبتكلفة فعالة."
        },
        
        
        
      },
    },
    fallbackLng: "en", // If detected language is not available, fallback to English
    debug: true, // Set to false in production
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    react: {
      useSuspense: false, // Turn off suspense if you are not using it
    }
  });

export default i18n;
