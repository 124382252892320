// src/components/ValueInnovation.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ValueInnovation = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <ValueSection>
      <ValueTitle>{t('VALUE INNOVATION')}</ValueTitle>
      <ValueDescription>
        {t('At Bridge Logistics, we are constantly seeking the best logistics solutions tailored to your needs. We embrace the concept of ‘value innovation,’ striving to deliver more while maintaining cost-efficiency. We take great pride in caring for our people, partners, customers, and stakeholders, all while committing to meeting stringent sustainability standards.')}
      </ValueDescription>
      <ReadMoreButton to="/About">{t('Read more')}</ReadMoreButton> 
    </ValueSection>
  );
};

export default ValueInnovation;

const ValueSection = styled.section`
  padding: 50px 100px;
  text-align: center;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 20px; /* Reduced padding for mobile devices */
  }
`;

const ValueTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Smaller font size for mobile */
  }
`;

const ValueDescription = styled.p`
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 30px;

  @media (max-width: 768px) {
    font-size: 1rem; /* Smaller font size for mobile */
    margin: 0 auto 20px; /* Reduced bottom margin */
    padding: 0 10px; /* Added padding for better readability */
  }
`;

const ReadMoreButton = styled(Link)`
  display: inline-block;
  padding: 15px 30px;
  background-color: #f7931e; /* Orange background */
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d6791a; /* Slightly darker orange on hover */
  }

  @media (max-width: 768px) {
    padding: 10px 20px; /* Adjusted padding for mobile */
    font-size: 0.9rem; /* Slightly smaller font size for mobile */
  }
`;
