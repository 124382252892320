// src/components/AboutSection.js

import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../assets/logistic.jpg';
import LogoBridge from '../assets/bridge-logo.png';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';

const AboutSection = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  return (
    <>
      <AboutContainer>
        <Breadcrumb>{t('Home')} &gt; {t('Discover us')} &gt; {t('About Bridge Logistics')}</Breadcrumb>
        <ContentWrapper isRtl={isRtl}>
          <LogoContainer>
            <Logo src={LogoBridge} alt="Bridge Logo" />
          </LogoContainer>
          <MissionStatement>
            <MissionTitle>{t('MISSION STATEMENT')}</MissionTitle>
            <MissionText>
              {t("PROVIDE LEADING LOGISTICS SOLUTIONS TO CONTINUOUSLY IMPROVE CUSTOMERS' GLOBAL COMPETITIVENESS WITH RESPECT FOR PEOPLE AND PLANET.")}
            </MissionText>
          </MissionStatement>
        </ContentWrapper>
      </AboutContainer>
      <AboutDetailsContainer>
        <AboutTitle>{t('ABOUT Bridge Logistics')}</AboutTitle>
        <AboutText>
          {t('Bridge Logistics is your trusted partner in navigating the complexities of the global supply chain. With over two decades of expertise, we excel in orchestrating seamless transportation solutions for your cargo, spanning international borders and markets. As active members of prestigious industry associations such as the Lebanese Forwarders Association, PCN, and ALN, we maintain a strong presence in the global logistics landscape. Our accreditation from the Ministry of Transportation in Lebanon underscores our unwavering commitment to adhering to industry regulations and delivering superior service.')}
        </AboutText>
        <AboutText>
          {t('At Bridge Logistics, we specialize in cross-trade shipments within Lebanon and the Middle East, leveraging our extensive network and strategic partnerships to optimize supply chain efficiency. Our comprehensive approach encompasses meticulous planning, streamlined execution, and proactive risk management to ensure your goods\' timely and secure delivery.')}
        </AboutText>
        <AboutText>
          {t('Beyond our core logistics operations, we foster collaboration and business growth through our affiliations with the Lebanese Dutch & Belgium Business Association and key chambers of commerce in Europe. These partnerships facilitate faultless trade connections and open doors to new opportunities for our clients in the global marketplace. Bridge Logistics is your gateway to tailored logistics solutions that drive your business forward. Experience the intersection of expertise, innovation, and dedication as we embark on a journey to optimize your supply chain and maximize your success.')}
        </AboutText>
      </AboutDetailsContainer>
      <Footer />
    </>
  );
};

export default AboutSection;

const AboutContainer = styled.section`
  position: relative;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  @media (max-width: 768px) {
    height: auto;
    padding: 20px;
    text-align: center;
    background-position: top;
  }
`;

const Breadcrumb = styled.div`
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 0.9rem;
  color: #f7931e;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;

  @media (max-width: 768px) {
    top: 5px;
    left: 10px;
    font-size: 0.8rem;
    padding: 3px 8px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 50px;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-inline: 20px;
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  height: 120px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MissionStatement = styled.div`
  flex: 2;
  background-color: rgba(247, 147, 30, 0.9);
  padding: 20px;
  max-width: 500px;
  text-align: left;
  border-radius: 5px;

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
    padding: 15px;
    margin-top: 20px;
  }
`;

const MissionTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const MissionText = styled.p`
  font-size: 1.2rem;
  color: #000;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AboutDetailsContainer = styled.section`
  padding: 50px 100px;
  background-color: #f5f5f5;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const AboutTitle = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const AboutText = styled.p`
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  max-width: 800px;
  margin-inline: auto;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: center;
  }
`;
