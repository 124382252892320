// src/components/Location.js

import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet-defaulticon-compatibility';
import Footer from './Footer';

// Import your custom marker icon if you have one
import markerIcon from '../assets/location-pin.png'; // Replace with your custom marker path if needed

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [30, 45], // Adjust size as needed
});

// Array of Lebanon-specific location data
const locations = [
  { lat: 33.8611, lng: 35.5422, name: 'Hazmieh, Beirut, Lebanon, St Rock Center' },
  // Add more specific locations within Lebanon if needed
];

const Location = () => {
  return (
    <>
    <Container>
      <Sidebar>
        <ContactDetails>
          <LocationIcon>📍</LocationIcon>
          <Address>
            Hazmieh, Saint Rock Centre, Ground Floor, Beirut - Lebanon
          </Address>
          <ContactItem>
            <PhoneIcon>📞</PhoneIcon>
            <ContactText>+961 5 951 171 - Ext 21</ContactText>
          </ContactItem>
          <ContactItem>
            <MobileIcon>📱</MobileIcon>
            <ContactText>+961 3 772 799</ContactText>
          </ContactItem>
        </ContactDetails>
      </Sidebar>
      <MapContainer
        center={[33.8547, 35.8623]} // Center the map on Lebanon
        zoom={10} // Adjust zoom level for a country-level view
        style={{ height: '80vh', width: '100%' }} // Full width and responsive height
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Using OpenStreetMap tiles
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={[location.lat, location.lng]}
            icon={customIcon} // Use the custom icon
          >
            <Popup>{location.name}</Popup> {/* Display the location name in a popup */}
          </Marker>
        ))}
      </MapContainer>
      
    </Container>
    <Footer />
    </>
  );
  
};

export default Location;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 80vh;

  @media (max-width: 768px) {
    flex-direction: column; // Stack the sidebar and map on smaller screens
    height: auto; // Allow content to determine the height on mobile
  }
`;

const Sidebar = styled.div`
  width: 300px; // Adjust width as needed
  padding: 20px;
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%; // Full width on mobile
    border-right: none;
    border-bottom: 1px solid #ddd; // Border below on mobile
    padding: 15px; // Reduce padding for mobile
  }
`;

const ContactDetails = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
`;

const Address = styled.p`
  margin-bottom: 15px;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const LocationIcon = styled.span`
  margin-right: 10px;
`;

const PhoneIcon = styled.span`
  margin-right: 10px;
`;

const MobileIcon = styled.span`
  margin-right: 10px;
`;

const ContactText = styled.span`
  color: #007bff; /* Blue color for links or emphasis */
`;
