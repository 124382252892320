// src/components/FeatureSection.js

import React from 'react';
import styled from 'styled-components';
import Image1 from '../assets/automotive.jpg';
import Image2 from '../assets/logistic.jpg';
import Image3 from '../assets/Sea Freight Shipping.jpg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FeatureSection = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  return (
    <SectionContainer>
      <SectionTitle>{t('NOBODY GOES FURTHER')}</SectionTitle>
      <FeatureGrid>
        <FeatureCard>
          <FeatureImage src={Image1} alt={t('Nunner Products')} />
          <FeatureTitle>
            {t('DISCOVER')} <Highlight>{t('Bridge Logistics Services')}</Highlight>
          </FeatureTitle>
          <FeatureDescription>
            {t('Discover how nobody goes further to get your goods where they have to go. On time. Every time.')}
          </FeatureDescription>
          <ReadMoreLink to="/Services">{t('Read more')} &gt;</ReadMoreLink>
        </FeatureCard>
        <FeatureCard>
          <FeatureImage src={Image2} alt={t('Specialist Knowhow')} />
          <FeatureTitle>
            {t('DISCOVER OUR')} <Highlight>{t('SPECIALIST KNOW HOW')}</Highlight>
          </FeatureTitle>
          <FeatureDescription>
            {t('Profit from our in-depth experience and specialist knowhow.')}
          </FeatureDescription>
          <ReadMoreLink to="/Team">{t('Read more')} &gt;</ReadMoreLink>
        </FeatureCard>
        <FeatureCard>
          <FeatureImage src={Image3} alt={t('Special Added Value')} />
          <FeatureTitle>
            {t('DISCOVER OUR')} <Highlight>{t('SPECIAL ADDED VALUE IN INDUSTRY')}</Highlight>
          </FeatureTitle>
          <FeatureDescription>
            {t('Learn how we can contribute to your bottom line.')}
          </FeatureDescription>
          <ReadMoreLink to="/Industry">{t('Read more')} &gt;</ReadMoreLink>
        </FeatureCard>
      </FeatureGrid>
    </SectionContainer>
  );
};

export default FeatureSection;

const SectionContainer = styled.section`
  padding: 50px 20px;
  text-align: center;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const SectionTitle = styled.h2`
  color: #f7931e;
  font-size: 2.5rem;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const FeatureGrid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureCard = styled.div`
  flex: 1;
  margin: 0 15px;
  text-align: left;
  max-width: 320px; /* Set a max-width for larger screens */

  @media (max-width: 768px) {
    margin-bottom: 20px;
    width: 90%; /* Ensure it doesn't exceed viewport width */
    max-width: 90%;
    text-align: center; /* Center the text for mobile */
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

const FeatureTitle = styled.h3`
  margin: 20px 0 10px;
  font-size: 1.25rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Highlight = styled.span`
  color: #f7931e;
`;

const FeatureDescription = styled.p`
  color: #666;
  margin-bottom: 10px;
`;

const ReadMoreLink = styled(Link)`
  color: #f7931e;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
