import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import TeamMember1 from '../assets/avatar.png';
import TeamMember2 from '../assets/avatar.png';
import TeamMember3 from '../assets/avatar.png';
import Footer from './Footer';

const Team = () => {
  const { t, i18n } = useTranslation();

  // Determine if the current language is RTL
  const isRtl = i18n.dir() === 'rtl';

  const teamMembers = [
    {
      name: 'Gaby El Azzi',
      position: t('CEO'),
      image: TeamMember1,
      description: t('GabyDescription'),
    },
    {
      name: 'Joseph El Jurdy',
      position: t('Accounting Manager'),
      image: TeamMember2,
      description: t('JosephDescription'),
    },
    {
      name: 'Pierre-Nadim Aoun',
      position: t('Assistant Commercial Manager'),
      image: TeamMember3,
      description: t('PierreDescription'),
    },
    {
      name: 'Antoinette Atallah',
      position: t('Sales Supervisor'),
      image: TeamMember3,
      description: t('AntoinetteDescription'),
    },
    {
      name: 'Elie Farhat and Mikel Kassouf',
      position: t('Sales Executives'),
      image: TeamMember3,
      description: t('ElieMikelDescription'),
    },
  ];

  return (
    <PageWrapper>
      <TeamContent>
        <Header>
          {t('Meet Our')} <span>{t('Team')}</span>
        </Header>
        <StyledSwiper
          spaceBetween={30}
          slidesPerView={1}
          // Always keep direction as LTR for consistent navigation behavior
          dir="ltr" 
          // Use reverse navigation for RTL languages to ensure the default direction to see other cards is to the right
          style={{ direction: isRtl ? 'ltr' : 'ltr' }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Pagination, Navigation]}
        >
          {teamMembers.map((member, index) => (
            <SwiperSlide key={index}>
              <TeamCard>
                <TeamImage src={member.image} alt={member.name} />
                <TeamInfo>
                  <TeamName>{member.name}</TeamName>
                  <TeamPosition
                    href="#"
                    roleColor={
                      index === 1 ? '#00bfa5' : index === 2 ? '#6a1b9a' : '#1565c0'
                    }
                  >
                    {member.position}
                  </TeamPosition>
                  <TeamText>{member.description}</TeamText>
                  <SocialLinks>
                    <SocialLink href="#">
                      <i className="fab fa-twitter"></i>
                    </SocialLink>
                    <SocialLink href="#">
                      <i className="fab fa-linkedin-in"></i>
                    </SocialLink>
                    <SocialLink href="#">
                      <i className="fab fa-instagram"></i>
                    </SocialLink>
                  </SocialLinks>
                </TeamInfo>
              </TeamCard>
            </SwiperSlide>
          ))}
        </StyledSwiper>
      </TeamContent>
      <Footer />
    </PageWrapper>
  );
};

export default Team;

// Styled components remain the same
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const TeamContent = styled.section`
  flex: 1;
  padding: 50px 20px;
  text-align: center;
  background-color: #fff;
`;

const Header = styled.h2`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  span {
    color: #007bff;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledSwiper = styled(Swiper)`
  width: 100%;
  padding: 20px 0;

  .swiper-pagination-bullet-active {
    background-color: #007bff;
  }
`;

const TeamCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
  height: 100%;

  &:hover {
    transform: translateY(-10px);
  }
`;

const TeamImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 20px;
`;

const TeamInfo = styled.div`
  padding: 0 20px;
`;

const TeamName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const TeamPosition = styled.a`
  display: block;
  color: ${({ roleColor }) => roleColor || '#007bff'};
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const TeamText = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: center;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const SocialLink = styled.a`
  color: #007bff;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover {
    color: #0056b3;
  }
`;
