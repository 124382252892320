import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

import BackgroundImage from '../assets/enlarge_ocean_freight.jpg';
import ServiceImage1 from '../assets/air freight.jpg';
import ServiceImage2 from '../assets/WAREHOUSING AND DISTRIBUTION.jpg';
import ServiceImage3 from '../assets/trailer-trucks-driving-road-surrounded-by-beautiful-green-trees.jpg';
import ServiceImage4 from '../assets/Customs Clearance.jpg';
import ServiceImage5 from '../assets/enlarge_ocean_freight.jpg';
import Modal from './Modal';
import Footer from './Footer';

const Services = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const serviceItems = [
    { 
      title: t('Ocean Freight'), 
      image: ServiceImage5, 
      description: t('Our ocean freight solutions provide reliable and cost-efficient options for shipping goods internationally. Whether you need Full Container Load (FCL) or Less than Container Load (LCL) shipments, we ensure punctual transit times and competitive pricing.') 
    },
    { 
      title: t('Air Freight'), 
      image: ServiceImage1, 
      description: t('Our air freight services offer expedited shipping solutions when time sensitivity is critical. We expertly handle urgent and time-critical shipments, ensuring swift and secure transport to global destinations.') 
    },
    { 
      title: t('Land Transportation'), 
      image: ServiceImage3, 
      description: t('Benefit from land transportation services connecting your supply chain across regions. Our extensive fleet and partner network guarantee prompt and dependable delivery for local distribution or cross-border operations.') 
    },
    { 
      title: t('Customs Clearance'), 
      image: ServiceImage4, 
      description: t('Our expertise is in navigating complex customs regulations. Our seasoned customs clearance team manages all import and export documentation facets, ensuring adherence to rules and smooth clearance at border crossings.') 
    },
    { 
      title: t('Warehousing & Distribution'), 
      image: ServiceImage2, 
      description: t('Utilize our state-of-the-art warehousing facilities strategically positioned to support your storage and distribution needs. From inventory optimization to order fulfillment, we provide flexible warehousing solutions tailored to your business.') 
    },
  ];

  const handleReadMore = (service) => {
    setSelectedService(service);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <ServicesContainer>
        <Breadcrumb>{t('Home')} &gt; {t('Services')}</Breadcrumb>
      </ServicesContainer>
      <ServicesContent>
        <ServicesTitle>{t('SERVICES')}</ServicesTitle>
        <ServicesDescription>
          {t('Our comprehensive logistics services provide you with the best solutions for your supply chain needs, ensuring that your goods are transported safely, efficiently, and cost-effectively.')}
        </ServicesDescription>
        <ServicesGrid>
          {serviceItems.map((item, index) => (
            <ServiceCard key={index}>
              <ServiceCardImage src={item.image} alt={item.title} />
              <ServiceCardTitle>{item.title}</ServiceCardTitle>
              <ReadMoreButton onClick={() => handleReadMore(item)}>{t('Read more')}</ReadMoreButton>
            </ServiceCard>
          ))}
        </ServicesGrid>
      </ServicesContent>
      <Footer />
      <Modal 
        isOpen={modalOpen} 
        onClose={closeModal} 
        title={selectedService?.title} 
        content={selectedService?.description} 
      />
    </>
  );
};

export default Services;

const ServicesContainer = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for mobile */
  }
`;

const Breadcrumb = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 1rem;
  color: #f7931e;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px;
  }
`;

const ServicesContent = styled.section`
  padding: 50px 20px;
  background-color: #f5f5f5;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ServicesTitle = styled.h2`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const ServicesDescription = styled.p`
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 50px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 30px;
  }
`;

const ServicesGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ServiceCard = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: center;
  width: 320px; /* Adjust the width to make the cards smaller */
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px 0;
  }
`;

const ServiceCardImage = styled.img`
  width: 100%;
  height: 220px; /* Adjust the height to fit the smaller cards */
  object-fit: cover;

  @media (max-width: 768px) {
    height: 180px; /* Adjust height for better mobile view */
  }
`;

const ServiceCardTitle = styled.h3`
  font-size: 1rem;
  color: #333;
  padding: 10px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ReadMoreButton = styled.button`
  background-color: #f7931e;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
