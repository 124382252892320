import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/bridge-logo.png'; // Replace with the correct path to your logo
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Quotation = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    countryOfOrigin: '',
    originZipCity: '',
    countryOfDestination: '',
    destinationZipCity: '',
    commodity: '',
    packages: '',
    weight: '',
    comments: '',
  });
  const [status, setStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send-quotation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setStatus('Quotation request sent successfully!');
        setFormData({
          companyName: '',
          email: '',
          countryOfOrigin: '',
          originZipCity: '',
          countryOfDestination: '',
          destinationZipCity: '',
          commodity: '',
          packages: '',
          weight: '',
          comments: '',
        });
      } else {
        setStatus('Failed to send quotation request.');
      }
    } catch (error) {
      console.error('Error sending quotation request:', error);
      setStatus('An error occurred. Please try again.');
    }
    setShowPopup(true); // Show the popup
    setTimeout(() => setShowPopup(false), 3000); // Hide after 3 seconds
  };

  return (
    <QuotationContainer>
      <QuotationHeader>
        <Title>{t('QUOTATION')}</Title>
        <Description>
          {t('At Bridge Logistics we want to give you the best possible value for money experience. Please fill in this form to start the process.')}
        </Description>
        <Logo src={logo} alt={t('Bridge Logistics Logo')} />
      </QuotationHeader>
      <Form onSubmit={handleSubmit}>
        <InputRow>
          <Input
            type="text"
            name="companyName"
            placeholder={t('Company name *')}
            value={formData.companyName}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder={t('Email *')}
            value={formData.email}
            onChange={handleChange}
            required
          />
        </InputRow>
        <SectionTitle>{t('WHERE FROM:')}</SectionTitle>
        <InputRow>
          <Input
            type="text"
            name="countryOfOrigin"
            placeholder={t('Country of origin *')}
            value={formData.countryOfOrigin}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="originZipCity"
            placeholder={t('Zip code and city of origin *')}
            value={formData.originZipCity}
            onChange={handleChange}
            required
          />
        </InputRow>
        <SectionTitle>{t('WHERE TO:')}</SectionTitle>
        <InputRow>
          <Input
            type="text"
            name="countryOfDestination"
            placeholder={t('Country of destination *')}
            value={formData.countryOfDestination}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="destinationZipCity"
            placeholder={t('Zip code and city of destination *')}
            value={formData.destinationZipCity}
            onChange={handleChange}
            required
          />
        </InputRow>
        <SectionTitle>{t('DESCRIPTION OF GOODS:')}</SectionTitle>
        <InputRow>
          <Input
            type="text"
            name="commodity"
            placeholder={t('Commodity *')}
            value={formData.commodity}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="packages"
            placeholder={t('Nr and type of packages')}
            value={formData.packages}
            onChange={handleChange}
          />
          <Input
            type="text"
            name="weight"
            placeholder={t('Gross weight')}
            value={formData.weight}
            onChange={handleChange}
          />
        </InputRow>
        <Textarea
          name="comments"
          placeholder={t('Comments')}
          value={formData.comments}
          onChange={handleChange}
        />
        <SubmitButton type="submit">{t('SEND')}</SubmitButton>
      </Form>
      {showPopup && <Popup>{status}</Popup>}
    </QuotationContainer>
  );
};

export default Quotation;

// Styled components
const QuotationContainer = styled.div`
  width: 100%;
  padding: 40px 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  direction: ltr; /* Ensures layout direction remains LTR */

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const QuotationHeader = styled.div`
  max-width: 1200px;
  margin: 0 auto 20px;
  text-align: left;
  position: relative;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Title = styled.h2`
  color: #333;
  font-size: 2rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.p`
  color: #666;
  font-size: 1rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Logo = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Form = styled.form`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SectionTitle = styled.h4`
  color: #f7931e;
  font-size: 1.1rem;
  margin: 20px 0 10px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #f7931e;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Popup animation
const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
`;

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeInOut} 3s forwards; /* 3 seconds animation */
  z-index: 1000;
`;
