import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/bridge-logo.png'; // Replace with the correct path to your logo
import BackgroundImage from '../assets/enlarge_ocean_freight.jpg'; // Replace with the correct path to your background image
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Contact = () => {
  const { t } = useTranslation(); // Use the translation hook
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const [status, setStatus] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        setStatus('Failed to send message.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('An error occurred. Please try again.');
    }
    setShowPopup(true); // Show the popup
    setTimeout(() => setShowPopup(false), 3000); // Hide after 3 seconds
  };

  return (
    <ContactPage>
      <ImageBanner>
        <LogoContainer>
          <Logo src={logo} alt={t('Bridge Logistics')} />
        </LogoContainer>
      </ImageBanner>
      <ContactContainer>
        <ContactHeader>
          <Title>{t('CONTACT US')}</Title>
          <Description>
            {t('Reach out to us for any inquiries, support, or feedback. We are here to assist you with your logistics needs.')}
          </Description>
        </ContactHeader>
        <ContactContent>
          <ContactForm onSubmit={handleSubmit}>
            <InputRow>
              <Input
                type="text"
                name="name"
                placeholder={t('Your Name *')}
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Input
                type="email"
                name="email"
                placeholder={t('Your Email *')}
                value={formData.email}
                onChange={handleChange}
                required
              />
            </InputRow>
            <Input
              type="text"
              name="subject"
              placeholder={t('Subject')}
              value={formData.subject}
              onChange={handleChange}
            />
            <Textarea
              name="message"
              placeholder={t('Your Message')}
              value={formData.message}
              onChange={handleChange}
            />
            <SubmitButton type="submit">{t('SEND MESSAGE')}</SubmitButton>
          </ContactForm>
          <ContactInfo>
            <ContactDetail>
              <DetailIcon>📍</DetailIcon>
              <DetailText>{t('Hazmieh, Saint Rock Centre, Ground Floor, Beirut - Lebanon')}</DetailText>
            </ContactDetail>
            <ContactDetail>
              <DetailIcon>📞</DetailIcon>
              <DetailText>{t('Phone')}: +961 5 951 171 - Ext 21</DetailText>
            </ContactDetail>
            <ContactDetail>
              <DetailIcon>📱</DetailIcon>
              <DetailText>+961 3 772 799</DetailText>
            </ContactDetail>
            <ContactDetail>
              <DetailIcon>✉️</DetailIcon>
              <DetailText>{t('info@bridgelog.net')}</DetailText>
            </ContactDetail>
          </ContactInfo>
        </ContactContent>
      </ContactContainer>
      {showPopup && <Popup>{status}</Popup>}
    </ContactPage>
  );
};

export default Contact;

// Styled components
const ContactPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  direction: ltr; /* Ensure the layout remains LTR */
`;

const ImageBanner = styled.div`
  width: 100%;
  height: 300px;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
  }
`;

const Logo = styled.img`
  height: 80px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 1200px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContactHeader = styled.div`
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 10px;
  }
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ContactForm = styled.form`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #f7931e;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

const ContactInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  color: #333;
`;

const DetailIcon = styled.span`
  margin-right: 10px;
  font-size: 1.5rem;
  color: #f7931e;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const DetailText = styled.span`
  font-size: 1rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Fade-in and fade-out animation for the popup
const fadeInOut = keyframes`
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
`;

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 30px;
  background-color: #333;
  color: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  animation: ${fadeInOut} 3s forwards; /* 3 seconds animation */
  z-index: 1000;
`;
