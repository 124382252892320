// src/components/Industry.js

import React, { useState } from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Modal from './Modal';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

import BackgroundImage from '../assets/logistic.jpg';
import IndustryImage1 from '../assets/industrial products1.jpg';
import IndustryImage2 from '../assets/Health Care and Pharmaceutical shipping.jpg';
import IndustryImage3 from '../assets/high tech.jpg';
import IndustryImage4 from '../assets/Medical Equipment.jpg';
import IndustryImage5 from '../assets/chemicals.jpg';
import IndustryImage6 from '../assets/Livestock by sea.jpg';
import IndustryImage7 from '../assets/Liquor and  Tobacco.jpg';
import IndustryImage8 from '../assets/Automotive sports.jpg';
import IndustryImage9 from '../assets/event.jpg';
import IndustryImage10 from '../assets/Air Charter.jpg';
import IndustryImage11 from '../assets/Projects carfo.jpg';
import IndustryImage12 from '../assets/perishable-goods-air-freight-forwarding.jpg';

const Industry = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const industryItems = [
    { title: t('Chemicals & Agribusiness'), image: IndustryImage5, description: t('In the chemicals and agribusiness sectors, safety and compliance are paramount. Our logistics solutions prioritize the secure transportation of hazardous materials and agricultural products, ensuring regulatory adherence and operational efficiency.') },
    { title: t('High Tech and Telecommunications'), image: IndustryImage3, description: t('In the fast-paced high-tech and telecommunications industries, precision and speed are non-negotiable. We offer agile logistics support to meet tight deadlines and handle sensitive electronic components with utmost care.') },
    { title: t('Health Care and Pharmaceutical'), image: IndustryImage2, description: t('The health care and pharmaceutical sectors demand impeccable logistics execution. We excel in handling pharmaceuticals, medical supplies, and equipment with strict adherence to regulatory standards and temperature-sensitive requirements.') },
    { title: t('Medical Equipment'), image: IndustryImage4, description: t('Transporting delicate medical equipment requires specialized expertise. We provide dedicated logistics solutions to ensure the safe and timely delivery of vital medical devices, supporting health care providers worldwide.') },
    { title: t('Industrial Products'), image: IndustryImage1, description: t('For manufacturers of industrial products, efficiency and reliability are key. Our logistics services optimize supply chain management for heavy machinery, equipment parts, and industrial components.') },
    { title: t('Live Stock'), image: IndustryImage6, description: t('We understand the unique challenges of transporting live animals. Our livestock logistics ensure the humane and safe movement of livestock, catering to the needs of the agriculture and farming industries.') },
    { title: t('Liquor and Cigarettes'), image: IndustryImage7, description: t('Secure and compliant transportation is essential for liquor and tobacco products. We offer specialized logistics solutions to handle these sensitive goods, guaranteeing timely delivery while meeting regulatory requirements.') },
    { title: t('Automotive and Sports'), image: IndustryImage8, description: t('Whether it\'s vehicles or sports equipment, we provide comprehensive logistics services tailored to the automotive and sports industries. Our solutions prioritize the protection and efficient delivery of valuable assets.') },
    { title: t('Events and Exhibition'), image: IndustryImage9, description: t('Planning an event or exhibition? Count on us for end-to-end logistics support. We manage the transportation, setup, and coordination of event materials to ensure a seamless experience.') },
    { title: t('Air Charter'), image: IndustryImage10, description: t('When urgency is paramount, our air charter services deliver flexible and efficient transportation solutions. We facilitate urgent or specialized cargo movements, providing swift and reliable air transportation.') },
    { title: t('Projects'), image: IndustryImage11, description: t('Complex projects demand meticulous logistics planning. Our project logistics expertise ensures the smooth execution of large-scale movements, from inception to completion.') },
    { title: t('Perishables'), image: IndustryImage12, description: t('Maintaining product quality during transit is critical for perishable goods. Our temperature-controlled logistics solutions preserve freshness and extend shelf life for perishable items, including fresh produce and seafood.') },
  ];

  const handleReadMore = (industry) => {
    setSelectedIndustry(industry);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedIndustry(null);
  };

  return (
    <>
      <IndustryContainer>
        <Breadcrumb>{t('Home')} &gt; {t('Industry')}</Breadcrumb>
      </IndustryContainer>
      <IndustryContent>
        <IndustryTitle>{t('INDUSTRY')}</IndustryTitle>
        <IndustryDescription>
          {t('Bridge Logistics stands out as a trusted partner in the world of logistics, providing specialized services across a wide spectrum of industries. Discover how we excel in delivering tailored solutions for your specific needs:')}
        </IndustryDescription>
        <IndustryGrid>
          {industryItems.map((item, index) => (
            <IndustryCard key={index}>
              <IndustryCardImage src={item.image} alt={item.title} />
              <IndustryCardTitle>{item.title}</IndustryCardTitle>
              <ReadMoreButton onClick={() => handleReadMore(item)}>{t('Read more')}</ReadMoreButton>
            </IndustryCard>
          ))}
        </IndustryGrid>
      </IndustryContent>
      <Footer />

      {/* Modal to display industry details */}
      <Modal 
        isOpen={modalOpen} 
        onClose={closeModal} 
        title={selectedIndustry?.title} 
        content={selectedIndustry?.description} 
      />
    </>
  );
};

export default Industry;

// Styles remain unchanged
const IndustryContainer = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;

  @media (max-width: 768px) {
    height: 300px; /* Adjust height for mobile */
  }
`;

const Breadcrumb = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 1rem;
  color: #f7931e;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 5px;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 5px 10px;
  }
`;

const IndustryContent = styled.section`
  padding: 50px 20px;
  background-color: #f5f5f5;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const IndustryTitle = styled.h2`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const IndustryDescription = styled.p`
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 50px;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 30px;
  }
`;

const IndustryGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IndustryCard = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: center;
  width: 320px; /* Adjust the width to make the cards smaller */
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
    margin: 10px 0;
  }
`;

const IndustryCardImage = styled.img`
  width: 100%;
  height: 220px; /* Adjust the height to fit the smaller cards */
  object-fit: cover;

  @media (max-width: 768px) {
    height: 180px; /* Adjust height for better mobile view */
  }
`;

const IndustryCardTitle = styled.h3`
  font-size: 1rem;
  color: #333;
  padding: 10px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ReadMoreButton = styled.button`
  background-color: #f7931e;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #d6791a;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
