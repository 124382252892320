// src/components/Header.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logoBridge from '../assets/bridge-logo.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Function to change the language
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = lng === 'ar' ? 'rtl' : 'ltr'; // Set the text direction based on the language
  };

  // Function to handle link click and close the menu
  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <HeaderWrapper>
      <TopBar>
        <TopBarLink to="/location">{t('locations')}</TopBarLink>
        <TopBarLink to="/contact">{t('contact')}</TopBarLink>
        <SearchBar type="text" placeholder={t('search_here')} />
      </TopBar>
      <MainNav>
        <MobileMenuButton onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
          ☰
        </MobileMenuButton>
        <LogoContainer>
          <Logo src={logoBridge} alt="Nunner Logo"loading="lazy" />
        </LogoContainer>
        <NavMenu isOpen={isMobileMenuOpen}>
          <NavItem to="/" onClick={handleLinkClick}>{t('Home')}</NavItem>
          <NavItem to="/about" onClick={handleLinkClick}>{t('Discover us')}</NavItem>
          <NavItem to="/industry" onClick={handleLinkClick}>{t('Industry')}</NavItem>
          <NavItem to="/services" onClick={handleLinkClick}>{t('Services')}</NavItem>
          <NavItem to="/team" onClick={handleLinkClick}>{t('Team')}</NavItem>
          {/* Hide Location and Contact NavItems on desktop */}
          <NavItemMobileOnly to="/location" onClick={handleLinkClick}>{t('Location')}</NavItemMobileOnly>
          <NavItemMobileOnly to="/contact" onClick={handleLinkClick}>{t('Contact')}</NavItemMobileOnly>
        </NavMenu>
        <LanguageSelector>
          <LanguageButton onClick={() => changeLanguage('en')}>EN</LanguageButton>
          <LanguageButton onClick={() => changeLanguage('ar')}>AR</LanguageButton>
        </LanguageSelector>
      </MainNav>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  direction: ${(props) => (props.dir === 'rtl' ? 'rtl' : 'ltr')};
`;

const TopBar = styled.div`
  background-color: #444;
  padding: 5px 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    display: none; /* Hide TopBar on mobile */
  }
`;

const TopBarLink = styled(Link)`
  color: white;
  margin-left: 20px;
  font-size: 0.9rem;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: #ffcc00;
  }
`;

const SearchBar = styled.input`
  padding: 5px;
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const MainNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: space-between; /* Align items properly for mobile */
  }
`;

const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start; /* Align logo to the left */

  @media (max-width: 768px) {
    justify-content: center; /* Center the logo on mobile */
  }
`;

const Logo = styled.img`
  height: 60px;

  @media (max-width: 768px) {
    height: 40px; /* Adjust logo size on mobile */
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    left: 10px;
  }
`;

const NavMenu = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    width: 100%;
    flex-direction: column;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    direction: ${(props) => (props.dir === 'rtl' ? 'rtl' : 'ltr')};
  }
`;

const NavItem = styled(Link)`
  color: #333;
  margin-inline: 15px;
  font-weight: bold;
  font-size: 1rem;
  text-decoration: none;

  &:hover {
    color: #ffcc00;
  }

  @media (max-width: 768px) {
    margin: 10px 0; /* Adjust spacing for mobile */
  }
`;

// Style for "Location" and "Contact" to only appear on mobile
const NavItemMobileOnly = styled(NavItem)`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const LanguageSelector = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    right: 10px;
  }
`;

const LanguageButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #f7931e;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;

  &:hover {
    background-color: #d6791a;
  }
`;
